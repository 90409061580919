const routesAdmin = [
  {
    path: '/admin',
    name: 'loginAdmin',
    component: () => import('../views/admin/LoginAdmin.vue'),
    meta: {
      showInNavBar: false,
      admin: true,
      auth: false,
    }
  },
  {
    path: '/admin/dashboard',
    name: 'dashboardAdmin',
    component: () => import('../views/admin/DashboardAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Panel de control',
      icon: 'pi pi-home',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/deportes',
    name: 'deportesAdmin',
    component: () => import('../views/admin/DeportesAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Deportes',
      icon: 'pi pi-globe',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/ligas',
    name: 'ligasAdmin',
    component: () => import('../views/admin/LigasAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Ligas',
      icon: 'pi pi-list',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/equipos',
    name: 'equiposAdmin',
    component: () => import('../views/admin/EquiposAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Equipos',
      icon: 'pi pi-users',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/grupos',
    name: 'gruposAdmin',
    component: () => import('../views/admin/GruposAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Grupos',
      icon: 'pi pi-bars',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/enfrentamientos',
    name: 'enfrentamientosAdmin',
    component: () => import('../views/admin/EnfrentamientosAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Enfrentamientos',
      icon: 'pi pi-table',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/resultados',
    name: 'resultadosAdmin',
    component: () => import('../views/admin/ResultadosAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Resultados',
      icon: 'pi pi-chart-bar',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/pagos',
    name: 'pagosAdmin',
    component: () => import('../views/admin/PagosAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Pagos',
      icon: 'pi pi-money-bill',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/apuestas',
    name: 'apuestasAdmin',
    component: () => import('../views/admin/ApuestasAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Apuestas',
      icon: 'pi pi-check-circle',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/usuarios',
    name: 'usuariosAdmin',
    component: () => import('../views/admin/UsuariosAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Usuarios',
      icon: 'pi pi-user',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/adminUsers',
    name: 'adminUsers',
    component: () => import('../views/admin/AdminUsers.vue'),
    meta: {
      showInNavBar: true,
      name: 'Usuarios Sistema',
      icon: 'pi pi-users',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/social',
    name: 'adminSocial',
    component: () => import('../views/admin/SocialLinksAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Redes sociales',
      icon: 'pi pi-link',
      admin: true,
      auth: true,
    }
  },
  {
    path: '/admin/config',
    name: 'configAdmin',
    component: () => import('../views/admin/ConfigAdmin.vue'),
    meta: {
      showInNavBar: true,
      name: 'Configuracion',
      icon: 'pi pi-cog',
      admin: true,
      auth: true,
    }
  },
]

export default routesAdmin