<script setup>
import LoadingScreen from './components/loading/LoadingScreen.vue';
import { useAppStore } from './store/appStore.js'

const store = useAppStore()
</script>

<template>
  <!-- <LoadingScreen v-if="store.state.loading" /> -->
  <router-view></router-view>
</template>

<style scoped>
</style>
