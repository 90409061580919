import axios from 'axios'
import { defineStore } from 'pinia'
import { ref } from 'vue'

/**
 * Store para llevar el control de la informacion de un usuario administrador
 */
export const useAdminStore = defineStore('admin', () => {
  /**
   * El equivalente al `state` de muchos stores, aqui guardamos toda la informacion
   * principal del usuario
   */
  const adminData = ref({
    token: ''
  })

  /**
   * Guardamos toda la info del administrador en el `sessionStorage`
   * @param { Object } data - La informacion del administrador 
   */
  const saveAdminData = (data) => {
    sessionStorage.setItem('adminuser', JSON.stringify(data))
    adminData.value = data
  }

  /**
   * Para cargar el token en la cabecera de axios, en caso de que tengamos
   * la info del usuario.
   */
  const initStore = () => {
    if (!sessionStorage.getItem('adminuser')) {
      sessionStorage.setItem('adminuser', JSON.stringify({ token: '' }))
      return
    }

    try {
      
      const adminDataSession = JSON.parse(sessionStorage.getItem('adminuser'))
      
      if (adminDataSession.token.length <= 0)
        return
      
      adminData.value = adminDataSession
      
      axios.defaults.headers.common["API-KEY"] = adminData.value.token
    } catch (error) {

      adminData.value = { token: '' }
      sessionStorage.setItem('adminuser', JSON.stringify({ token: '' }))
    }
  }

  /**
   * Para limpiar el store del administrador cuando hacemos signout
   */
  const cleanStore = () => {
    adminData.value = { token: '' }
    saveAdminData(adminData.value)
  }

  return {
    adminData,
    saveAdminData,
    initStore,
    cleanStore,
  }
})