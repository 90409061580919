import { defineStore } from 'pinia'
import { ref } from 'vue'
import { usePrimeVue } from 'primevue/config'

/**
 * Store para manejar el cambio de los temas
 */
export const useThemeStore = defineStore('theme', () => {
  // Temas de la aplicacion
  const themeLight = 'aura-light-green'
  const themeDark = 'aura-dark-green'
  
  // Tema actual
  const currentTheme = ref(themeLight)
  const isDark = ref(false)

  /**
   * Obtiene el tema por el cual la aplicacion debe cambiar
   * @returns { String }
   */
  const themeToChange = () => {
    if (currentTheme.value === themeLight) {
      isDark.value = false
      return themeDark
    }

    isDark.value = true
    return themeLight
  }

  /**
   * Actualiza `currentTheme` al tema que se va a utilizar ademas
   * de guardar en el `localStorage` la info relacionada al tema
   */
  const updateTheme = () => {
    if (currentTheme.value === themeLight) {
      currentTheme.value = themeDark
      saveStore()
      return
    }

    currentTheme.value = themeLight
    saveStore()
    return
  }

  /**
   * Guardamos las preferencias del tema en el `localStorage`
   */
  const saveStore = () => {
    localStorage.setItem('theme', JSON.stringify({ theme: currentTheme.value }))
  }

  /**
   * Metodo para cargar el tema seleccionado, este metodo se debe llamar en los layouts principales
   * del administrador y el usuario comun.
   */
  const init = () => {
    if (localStorage.getItem('theme')) {
      const theme = JSON.parse(localStorage.getItem('theme'))
      
      currentTheme.value = theme.theme
      const PrimeVue = usePrimeVue()
      PrimeVue.changeTheme(themeToChange(), currentTheme.value, 'theme-link', () => {})
    }
  }

  return {
    themeToChange,
    currentTheme,
    init,
    isDark,
    updateTheme,
    themeLight,
    themeDark,
  }
})