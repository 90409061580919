import { createApp, markRaw } from 'vue'
import App from './App.vue'
import axios from 'axios'
// Icons
import 'primeicons/primeicons.css'

// Prime flex for utils to use in css
import '/node_modules/primeflex/primeflex.css'

// Primevue
import Ripple from 'primevue/ripple'
import Tooltip from 'primevue/tooltip'

// Primevue global components
import Toast from 'primevue/toast'
import ConfirmDialog from 'primevue/confirmdialog'
// Plugins
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import pinia from './store/store'
import router from './router'

// Store to init
import { useThemeStore } from './store/themeStore'
import { useAdminStore } from './store/adminStore'
import { useUsuarioStore } from './store/usuarioStore'

import env from './env'

const app = createApp(App)

// axios config
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = "0";
axios.defaults.baseURL = env.API_URL

// directives
app.directive('ripple', Ripple)
app.directive('tooltip', Tooltip)

// global components
app.component('Toast', Toast)
app.component('ConfirmDialog', ConfirmDialog)

// global properties
app.provide('$env', env)

// Plugins
app.use(PrimeVue, {
  ripple: true,
})
app.use(ToastService)
app.use(ConfirmationService)
// Pinia
app.use(pinia)
// Set the theme
const themeStore = useThemeStore()
// themeStore.init()

app.use(router)

app.mount('#app')
