import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import routesAdmin from './routerAdmin'
import routesUsuario from './routerUsuario'
import { useAdminStore } from '../store/adminStore'
import { useUsuarioStore } from '../store/usuarioStore'
import axios from 'axios'
import { useAppStore } from '../store/appStore'

const routes = [
  ...routesUsuario,
  ...routesAdmin,
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFoundView.vue')
  },
]

const router = createRouter({
  routes,
  history: createWebHashHistory(),
})

const handleAuth = async (token, redirect, next) => {
  if (token === '' || token === undefined || token === null)
    return next({ name: redirect }, () => {})

  if (token.length <= 0)
    return next({ name: redirect }, () => {})

  let res = { status: 200 }

  try {
    res = await axios.post('/v1/auth', {}, {
      headers: {
        'API-KEY': token,
      }
    })
  } catch (error) {
    res.status = 400
  }

  if (res.status !== 200)
    return next({name: redirect}, () => {})
  return next()
}

router.beforeEach(async (to, from, next) => {
  const storeApp = useAppStore()

  storeApp.startLoading()
  // Comprobar si es necesario checar el estatus del token
  if (!to.meta?.auth)
    return next()
  
  // Admin logic
  if (to.meta.admin) {
    const adminStore = useAdminStore()
    adminStore.initStore()
    const token = adminStore.adminData?.token
    return handleAuth(token, 'loginAdmin', next)
  }
  if (to.meta.user) {
    const userStore = useUsuarioStore()
    userStore.initStore()
    const token = userStore.usuarioData?.token
    return handleAuth(token, 'loginUsuario', next)
  }

  return next()
})

router.afterEach((to, from, next) => {
  const storeApp = useAppStore()
  setTimeout(() => {
    storeApp.stopLoading()
  }, 300);
})

export default router