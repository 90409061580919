import { defineStore } from "pinia";
import { ref } from 'vue'
import axios from 'axios'

/**
 * Store para manejar toda la informacion del usuario normal.
 */
export const useUsuarioStore = defineStore('usuarios', () => {
    const sessionStorageKey = 'usuarioData'
    
    const defaultState = {
        id: 0,
        username: '',
        token: '',
    }
    const usuarioData = ref(defaultState)

    /**
     * Para guardar la informacion del usuario en el `sessionStorage`
     * @param { Object } data 
     */
    const saveUsuarioData = (data) => {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(data))
        usuarioData.value = data
    }

    /**
     * Para cargar toda la informacion del usuario y colocar el api-key deacuerdo al
     * token del usuario en caso de que aplique.
     */
    const initStore = () => {
        if (!sessionStorage.getItem(sessionStorageKey)) {
            sessionStorage.setItem(sessionStorageKey, JSON.stringify(defaultState))
            return
        }

        try {
            const usuarioDataSession = JSON.parse(sessionStorage.getItem(sessionStorageKey))
            
            if (usuarioDataSession.token.length <= 0)
                return

            usuarioData.value = usuarioDataSession

            axios.defaults.headers.common["API-KEY"] = usuarioData.value.token
        } catch (error) {
            cleanStore()
        }
    }

    /**
     * Para limpiar el store cuando hacemos signout
     */
    const cleanStore = () => {
        usuarioData.value = defaultState
        saveUsuarioData(usuarioData.value)
    }

    return {
        usuarioData,
        saveUsuarioData,
        initStore,
        cleanStore,
    }
})