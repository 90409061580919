const routesUsuario = [
  {
    path: '/',
    name: 'loginUsuario',
    component: () => import('../views/usuario/LoginUsuario.vue'),
    meta: {
      auth: false,
      user: true,
      showInDesktop: false,
    }
  },
  {
    path: '/dashboard',
    name: 'dashboardUsuario',
    component: () => import('../views/usuario/DashboardUsuario.vue'),
    meta: {
      auth: true,
      user: true,
      showInDesktop: false,
    },
  },
  {
    path: '/perfil',
    name: 'userProfile',
    component: () => import('../views/usuario/ProfileView.vue'),
    meta: {
      auth: true,
      user: true,
      showInDesktop: false,
    },
  },
  {
    path: '/apostar',
    name: 'bet',
    component: () => import('../views/usuario/BetView.vue'),
    meta: {
      auth: true,
      user: true,
      icon: 'pi pi-check-circle',
      name: 'Apostar',
      showInDesktop: false,
      order: 2,
    },
  },
  {
    path: '/apostar_detalles',
    name: 'betDetails',
    component: () => import('../views/usuario/BetDetailsView.vue'),
    meta: {
      auth: true,
      user: true,
      showInDesktop: false,
    },
  },
  {
    path: '/mis_apuestas',
    name: 'myBets',
    component: () => import('../views/usuario/MyBetsView.vue'),
    meta: {
      auth: true,
      user: true,
      showInDesktop: false,
    },
  },
  {
    path: '/principal',
    name: 'mainPage',
    component: () => import('../views/usuario/MainPageView.vue'),
    meta: {
      auth: true,
      user: true,
      showInDesktop: false,
      icon: 'pi pi-home',
      name: 'Inicio',
      showInDesktop: true,
      order: 1,
    },
  },
  {
    path: '/buscar',
    name: 'search',
    component: () => import('../views/usuario/SearchView.vue'),
    meta: {
      auth: true,
      user: true,
      showInDesktop: false,
    },
  },
  {
    path: '/deportes',
    name: 'deportesUsuario',
    component: () => import('../views/usuario/DeportesView.vue'),
    meta: {
      auth: true,
      user: true,
      icon: 'pi pi-globe',
      showInDesktop: false,
      name: 'Deportes',
      order: 3,
    },
  },
  {
    path: '/ligas',
    name: 'ligasUsuario',
    component: () => import('../views/usuario/LigasView.vue'),
    meta: {
      auth: true,
      user: true,
      icon: 'pi pi-list',
      showInDesktop: false,
      name: 'Ligas',
      order: 4,
    },
  },
  {
    path: '/equipos',
    name: 'equiposUsuario',
    component: () => import('../views/usuario/EquiposView.vue'),
    meta: {
      auth: true,
      user: true,
      icon: 'pi pi-users',
      showInDesktop: false,
      name: 'Equipos',
      order: 5,
    },
  },

  // BackURLS de mercado pago
  {
    path: '/success',
    name: 'mercadoPagoSuccess',
    component: () => import('../views/usuario/SuccessView.vue'),
    meta: {
      auth: true,
      user: true,
      icon: '',
      showInDesktop: false,
      name: '',
    }
  },
  {
    path: '/failure',
    name: 'mercadoPagoFailure',
    component: () => import('../views/usuario/FailureView.vue'),
    meta: {
      auth: true,
      user: true,
      icon: '',
      showInDesktop: false,
      name: '',
    }
  },
  {
    path: '/pending',
    name: 'mercadoPagoPending',
    component: () => import('../views/usuario/PendingView.vue'),
    meta: {
      auth: true,
      user: true,
      icon: '',
      showInDesktop: false,
      name: '',
    }
  },
]

export default routesUsuario