import { defineStore } from 'pinia';
import { ref } from 'vue'

export const useAppStore = defineStore('appStore', () => {
    const state = ref({
        loading: false,
    })

    const startLoading = () => {
        state.value.loading = true
    }

    const stopLoading = () => {
        state.value.loading = false
    }

    return {
        state,
        startLoading,
        stopLoading,
    }
});
